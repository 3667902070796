import { useEffect, useRef, useState } from "react";

export const CustomSelect = ({
  questionNumber,
  questionText,
  selectVariants,
  answer,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Выбрать..");
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const menuRef = useRef();

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  function openSelect() {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }

  return (
    <div className="questionContent">
      <div className="questionHalf">
        {" "}
        <div className="questionText">
          {questionNumber}. {questionText}
        </div>
      </div>{" "}
      <div className="questionHalf">
        {" "}
        <div className="questionSelect" onClick={openSelect} ref={ref}>
          {selected}
        </div>
        {isOpen && (
          <div
            style={{ marginTop: height + 5 }}
            className="selectVariants"
            ref={menuRef}
          >
            {selectVariants.map((item) => {
              return (
                <div
                  key={item}
                  onClick={() => {
                    setSelected(item);
                    answer(questionNumber, questionText, item);
                    setIsOpen(false);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
