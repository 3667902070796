export const StageHeader = ({ stageText, stageNumber }) => {
  return (
    <div className="stageHeader">
      <div className="contactingTitle">Уважаемый респондент!</div>
      <div className="stageTitleBox">
        <div className="stageHeaderText">{stageText}</div>
        <div className="stageHeaderNumber">Этап {stageNumber}</div>
      </div>
    </div>
  );
};
