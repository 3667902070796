import axios from "axios";

class Send {
  static url_api = "https://www.anncom.ru/dialer/newxyz/25.py";

  static async sendAll(dataJson) {
    let result;
    await axios
      .post(this.url_api, {
        dataJson,
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error;
      });
    return result;
  }
}

export default Send;
