import { useEffect, useState } from "react";

export const CustomNextQuestion = ({
  number,
  question,
  answer,
  change,
  disable,
  stage,
}) => {
  const [questionDisable, setQuestionDisable] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [stage]);

  function checkQuestion(e) {
    if (e.target.checked) {
      change(number, question, answer, true);
      setChecked(true);
    }
    if (!e.target.checked) {
      change(number, question, answer, false);
      setChecked(false);
    }
  }

  useEffect(() => {
    if (!checked && disable) {
      setQuestionDisable(true);
    } else setQuestionDisable(false);
  }, [checked, disable]);

  return (
    <div className="questionBox">
      <div className="questionContent nextQuestionContent">
        <label className="container">
          {" "}
          <input
            onChange={checkQuestion}
            value={question}
            type="checkbox"
            name=""
            id=""
            disabled={questionDisable}
          />
          <span className="checkmark nextCheckmark"></span>
        </label>

        <div className="questionHalf">
          <div className="questionText">{question}</div>
        </div>
        <div className="questionHalf">
          {" "}
          <div className="questionInput questionInputNext">{answer}</div>
        </div>
      </div>
    </div>
  );
};
