export const FinalMessage = ({ code }) => {
  window.scrollTo(0, 0);
  document.body.style.overflow = "hidden";

  function closeFinal() {
    localStorage.clear();

    document.body.style.overflow = "";
    document.location.replace("https://rgsu.net/25points/");
  }

  return (
    <div className="popUp">
      <div className="finalMessage">
        <div className="finalMessageTitleBox">
          <div className="finalMessageTitle">ПОЗДРАВЛЯЕМ, ОПРОС ЗАВЕРШЕН!</div>
          <div className="thankfullTitle">БЛАГОДАРИМ ВАС ЗА УЧАСТИЕ!</div>
        </div>
        <div className="finalMessageContent">
          <div className="personalCodeBox">
            <div className="personalCode">{code}</div>
          </div>
          <div className="finalText">
            <p>
              {" "}
              Это Ваш личный уникальный номер участника опроса. На основании
              данного номера Вы становитесь участником розыгрыша призов проекта
              «25 пунктов».
            </p>
            <p>
              Определение победителя будет проведено 06.05.2024 путем случайного
              выбора номеров участников. Процедура определения призеров будет
              организована в онлайн-формате с трансляцией в прямом эфире на
              официальных ресурсах РГСУ:
              <br></br>
              <a href="https://vk.com/rgsu_official">ВК</a>
              <br></br>
              <a href="https://rutube.ru/u/rgsu/">Rutube</a>
            </p>
            <p>
              Если у Вас остались вопросы, мы с радостью ответим на них.
              Обращайтесь по <a href="mailto:25points@rgsu.net">адресу</a> или в
              нашем <a href="https://t.me/points25_bot">Telegram-боте</a>
            </p>
          </div>
        </div>
        <div className="nextStageButtonBox">
          <div className="nextStageButton" onClick={closeFinal}>
            ЗАКРЫТЬ
          </div>
        </div>
      </div>
    </div>
  );
};
