import { Route, Routes } from "react-router-dom";
import { FirstStage } from "./Pages/FirstStage";
import { NextStage } from "./Pages/NextStage";
import React from "react";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<FirstStage />} />
        <Route path="/next" element={<NextStage />} />
      </Routes>
    </div>
  );
}
//test
export default App;
