export const questionArray = [
  {
    questionType: "input",
    questionNumber: 1,
    questionText: "Возраст",
  },
  {
    questionType: "select",
    questionNumber: 2,
    questionText: "Выберите пол",
    variants: ["Мужской", "Женский"],
  },
  {
    questionType: "select",
    questionNumber: 3,
    questionText: "Cемейное положение",
    variants: [
      "Женат (замужем)",
      "В гражданском (неофициальном) браке",
      "Не женат (не замужем)",
      "Разведен(а)",
      "Вдовец/вдова",
      "Все сложно",
    ],
  },
  {
    questionType: "select",
    questionNumber: 4,
    questionText: "Дети",
    variants: ["Нет детей", "1", "2", "3", "4", "5", "6", "7"],
  },
  {
    questionType: "select",
    questionNumber: 5,
    questionText: "Cфера деятельности",
    variants: [
      "Без акцентов на специфику",
      "Безопасность и правоохранение",
      "Бизнес и предпринимательство",
      "Информационные технологии",
      "Медицина и здравоохранение",
      "Менеджмент и управление",
      "Педагогика и воспитание",
      "Промышленность и индустрия",
      "Связь и коммуникации",
      "Строительство и транспорт",
      "Торговля и сервисы",
      "Финансовые инструменты",
      "Сельское хозяйство",
    ],
  },
  {
    questionType: "select",
    questionNumber: 6,
    questionText: "Профессия",
    variants: [
      "Квалифицированный рабочий, включая сельское хозяйство",
      "Неквалифицированный рабочий, включая сельское хозяйство",
      "Служащие без высшего образования",
      "Специалист с высшим образованием  в коммерческом секторе",
      "Специалист с высшим образованием – бюджетник",
      "Военнослужащий",
      "Бизнесмен, предприниматель",
      "Государственный или муниципальный служащий",
      "Неработающий пенсионер",
      "Временно не работаю",
      "Учусь",
    ],
  },
  {
    questionType: "select",
    questionNumber: 7,
    questionText: "Должность",
    variants: [
      "руководитель",
      "специалист",
      "служащий",
      "рабочий",
      "предприниматель",
      "самозанятый",
      "временно безработный",
    ],
  },
  {
    questionType: "select",
    questionNumber: 8,
    questionText: "Образование",
    variants: [
      "Основное общее образование",
      "Среднее общее образование",
      "Среднее профессиональное образование",
      "Высшее образование— бакалавриат",
      "Высшее образование — специалитет, магистратура",
      "Два и более высших образования",
      "Ученая степень",
      "Без образования",
    ],
  },
  {
    questionType: "select",
    questionNumber: 9,
    questionText: "Материальное положение",
    variants: [
      "Достаток. Не испытываю затруднений, помогаю материально другим",
      "Устойчивое. Нет никаких материальных затруднений",
      "Стабильное. Особых материальных затруднений нет, но не все покупки по карману",
      "Нуждаюсь. Денег хватает лишь на основные продукты питания и одежду",
      "Бедность. Денег не хватает на продукты питания, лекарства, одежду",
      "Нищета. Живу в крайней нужде",
    ],
  },
  {
    questionType: "select",
    questionNumber: 10,
    questionText: "Увлечение",
    variants: [
      "Спорт",
      "Искусство",
      "Творчество",
      "Экстрим",
      "Коллекционирование",
      "Рукоделие",
      "Животные",
      "Садоводсвто",
      "Цветоводство",
      "Путешетвие/Туризм",
      "Игры (настольные, компьютерные, карточные и т.д.)",
      "Чтение",
      "Моделизм",
      "Кулинария",
      "Саморазвитие/Самообразование",
      "Шопинг",
      "Другое",
      "Нет увлечения",
    ],
  },
];
