import { useState } from "react";

export const CustomInput = ({ questionNumber, questionText, answer }) => {
  const [input, setInput] = useState("");

  function numberInput(event) {
    if (Number(event) || event == 0) {
      setInput(event);
      answer(questionNumber, questionText, event);
    } else {
      return;
    }
  }

  return (
    <div className="questionContent">
      <div className="questionHalf">
        <div className="questionText">
          {questionNumber}. {questionText}
        </div>
      </div>
      <div className="questionHalf">
        <input
          value={input}
          onInput={(e) => {
            numberInput(e.target.value);
          }}
          className="questionInput"
        ></input>
      </div>
    </div>
  );
};
