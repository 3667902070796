import { useEffect, useState } from "react";
import { StageHeader } from "./StageHeader";
import { CustomNextQuestion } from "../Questions/CustomNextQuestion";
import { FinalMessage } from "../FinalMessage";
import Send from "../../Api/Send";
import { ErrorMessage } from "../ErrorMessage";

export const NextStageBody = () => {
  const [stageNumber, setStageNumber] = useState(2);
  const [pointText, setPointsText] = useState("пунктов");
  const [answerArray, setAnswerArray] = useState([]);
  const [disableCheck, setDisableCheck] = useState(false);
  const [answersNumber, setAnswersNumber] = useState(7);
  const [finalMessage, setFinalMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState("Выберите семь пунктов");
  const [code, setCode] = useState("");
  const [savedArray, setSavedArray] = useState(
    JSON.parse(localStorage.getItem("answers")) || []
  );
  const [sendAnswers, setSendAnswers] = useState(
    JSON.parse(localStorage.getItem("sendAnswers")) || []
  );

  useEffect(() => {
    console.log(sendAnswers);
  });

  function sort() {
    for (let i = 0; i < answerArray.length; i++) {
      answerArray[i].number = Number(answerArray[i].number);
    }
    answerArray.sort((a, b) => (a.number > b.number ? 1 : -1));
  }

  function nextStageNumber() {
    if (stageNumber < 4) {
      if (
        (stageNumber === 2 && answerArray.length === 7) ||
        (stageNumber === 3 && answerArray.length === 5)
      ) {
        window.scrollTo(0, 0);
        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((e) => (e.checked = false));
        sort();
        setSavedArray(answerArray);
        setAnswerArray([]);
        setStageNumber(stageNumber + 1);
        setErrorMessage(false);
        if (stageNumber === 2) {
          setSendAnswers({ ...sendAnswers, secondStage: answerArray });
        }
        if (stageNumber === 3) {
          setSendAnswers({ ...sendAnswers, thirdStage: answerArray });
        }
      } else {
        setErrorMessage(true);
        return;
      }
    } else {
      if (stageNumber === 4 && answerArray.length === 3) {
        window.scrollTo(0, 0);

        let finalAnswers = sendAnswers;

        finalAnswers = { ...finalAnswers, fourthStage: answerArray };

        setSendAnswers({ ...sendAnswers, fourthStage: answerArray });

        const result = async () => {
          setCode(await Send.sendAll(finalAnswers));
        };
        result();
      } else {
        setErrorMessage(true);
        return;
      }
    }
  }

  useEffect(() => {
    if (code.success === true) {
      setAnswerArray([]);
      setSendAnswers([]);
      setFinalMessage(true);
    } else if (code.message === "Такой номер уже зарегистрирован") {
      setErrorText("Такой номер уже зарегистрирован");
      setErrorMessage(true);
      return;
    } else if (code.success === false) {
      setErrorText("Возникла ошибка");
      setErrorMessage(true);
      return;
    }
  }, [code]);

  function addAnswer(number, text, answer, checked) {
    if (checked === true) {
      setAnswerArray([
        ...answerArray,
        { number: number, text: text, answer: answer },
      ]);
    }
    if (checked === false) {
      setAnswerArray(answerArray.filter((e) => e.text !== text));
    }
  }

  useEffect(() => {
    if (answerArray.length === 7 && stageNumber === 2) {
      setDisableCheck(true);
    } else if (answerArray.length === 5 && stageNumber === 3) {
      setDisableCheck(true);
    } else if (answerArray.length === 3 && stageNumber === 4) {
      setDisableCheck(true);
    } else {
      setDisableCheck(false);
    }
  }, [answerArray, stageNumber]);

  useEffect(() => {
    if (stageNumber === 2) {
      setAnswersNumber(7);
      setErrorText("Выберите семь пунктов");
      setPointsText("пунктов");
    }
    if (stageNumber === 3) {
      setAnswersNumber(5);
      setErrorText("Выберите пять пунктов");
      setPointsText("пунктов");
    }
    if (stageNumber === 4) {
      setAnswersNumber(3);
      setErrorText("Выберите три пункта");
      setPointsText("пункта");
    }
  }, [stageNumber]);

  return (
    <div className="stageBody">
      <StageHeader
        stageText={`Теперь проставьте галочки напротив тех пунктов, которые, по Вашему мнению, характеризуют Вас лучше всего (обращаем внимание, что выбрать можно только ${answersNumber} ${pointText})`}
        stageNumber={stageNumber}
      ></StageHeader>
      <div className="stageContent nextStageContent">
        {" "}
        {finalMessage && <FinalMessage code={code.code}></FinalMessage>}
        {savedArray.map((item) => {
          return (
            <CustomNextQuestion
              key={item.text}
              number={item.number}
              question={item.text}
              answer={item.answer}
              change={addAnswer}
              disable={disableCheck}
              stage={stageNumber}
            ></CustomNextQuestion>
          );
        })}
        <div className="nextStageButtonBox">
          <div className="nextStageButton" onClick={nextStageNumber}>
            ОТПРАВИТЬ
          </div>
        </div>{" "}
        <div className="errorBox">
          {errorMessage && <ErrorMessage text={errorText}></ErrorMessage>}
        </div>
      </div>
    </div>
  );
};
