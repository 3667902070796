import { useEffect, useState } from "react";
import { questionArray } from "../../questionArray";
import { CustomInput } from "../Questions/CustomInput";
import { CustomSelect } from "../Questions/CustomSelect";
import { StageHeader } from "./StageHeader";
import { useNavigate } from "react-router-dom";
import approveFile from "../../Assets/docs/Согласие_на_обработку_персональных_данных.pdf";
import InputMask from "react-input-mask";
import { ErrorMessage } from "../ErrorMessage";

export const FirstStageBody = () => {
  const [phone, setPhone] = useState("");
  const [answerArray, setAnswerArray] = useState([]);
  const [approveValidate, setApproveValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [region, setRegion] = useState("+7");
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "";
  }, []);

  function numberInput(event) {
    setPhone(event.target.value);
  }

  function changeRegion(e) {
    setRegion(e.target.value);
    setPhone("");
  }

  function sort() {
    for (let i = 0; i < answerArray.length; i++) {
      answerArray[i].number = Number(answerArray[i].number);
    }
    answerArray.sort((a, b) => (a.number > b.number ? 1 : -1));
  }

  function addAnswer(number, text, value) {
    const findExistingItem = answerArray.find((item) => {
      return item.text === text;
    });

    if (findExistingItem) {
      setAnswerArray((prevValues) =>
        prevValues.filter((value) => value.text !== findExistingItem.text)
      );

      setAnswerArray((state) => [
        ...state,
        { number: number, text: text, answer: value },
      ]);
    } else {
      setAnswerArray((state) => [
        ...state,
        { number: number, text: text, answer: value },
      ]);
    }
  }

  function nextPage() {
    const age = answerArray.find((item) => {
      return item.text === "Возраст";
    });

    let phoneNumberTest;
    let phoneTest;

    if (region === "+7") {
      phoneTest = /^[\d\+][\d\(\)\ -]{4,14}\d$/;
      phoneNumberTest = "+7" + phone;
    } else {
      phoneTest = /^\D*(?:\d\D*){12,}$/;
      phoneNumberTest = "+375" + phone;
    }

    if (
      answerArray.length === 10 &&
      phoneTest.test(phoneNumberTest) &&
      approveValidate
    ) {
      if (age.answer > 13 && age.answer < 100) {
        window.scrollTo(0, 0);
        navigate("/next");
        sort();
        localStorage.setItem(
          "sendAnswers",
          JSON.stringify({ phone: phoneNumberTest, firstStage: answerArray })
        );
        localStorage.setItem("answers", JSON.stringify(answerArray));
      } else {
        setErrorText("Возраст должен быть от 14 до 99 лет");
        setErrorMessage(true);
      }
    } else {
      setErrorText("Пожалуйста, ответьте на все вопросы");
      setErrorMessage(true);
    }
  }

  function onApproveChange(e) {
    if (e.target.checked) {
      setApproveValidate(true);
    } else {
      setApproveValidate(false);
    }
  }

  function openApproveFile() {
    window.open(approveFile);
  }

  return (
    <div className="stageBody">
      <StageHeader
        stageText={
          "Предлагаем Вам ответить на 10 простых вопросов, выбрав ответы из выпадающих вариантов."
        }
        stageNumber={1}
      ></StageHeader>
      <div className="stageContent">
        {questionArray.map((question) => {
          return (
            <div key={question.questionNumber} className="questionBox">
              {question.questionType === "select" ? (
                <CustomSelect
                  questionNumber={question.questionNumber}
                  questionText={question.questionText}
                  selectVariants={question.variants}
                  answer={addAnswer}
                ></CustomSelect>
              ) : question.questionType === "input" ? (
                <CustomInput
                  questionNumber={question.questionNumber}
                  questionText={question.questionText}
                  answer={addAnswer}
                ></CustomInput>
              ) : (
                <></>
              )}
            </div>
          );
        })}
        <div className="questionBox">
          <div className="questionContent">
            <div className="questionHalf">
              <div className="questionText">Номер телефона</div>
            </div>
            <div className="questionHalf">
              <div className="phoneBox">
                {" "}
                <select className="selectRegion" onChange={changeRegion}>
                  <option value={"+7"}>+7</option>
                  <option value={"+375"}>+375</option>
                </select>
                <InputMask
                  mask={region === "+7" ? "(999)999-99-99" : "(99)999-99-99"}
                  value={phone}
                  onChange={numberInput}
                  className="questionInput phoneInput"
                ></InputMask>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="numberDescriptionBox">
          <div className="numberDescription">
            Номер телефона нужен нам для того, чтобы связаться с Вами в случае
            победы
          </div>
        </div>

        <div className="questionBox">
          <div className="approveBox">
            <div className="approveContent">
              {" "}
              <a onClick={openApproveFile}>
                Я даю свое согласие на обработку моих персональных данных
              </a>
              <label className="container approveContainer">
                {" "}
                <input type="checkbox" onChange={onApproveChange} />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="nextStageButtonBox" onClick={nextPage}>
          <div className="nextStageButton">ОТПРАВИТЬ</div>
        </div>
        <div className="errorBox">
          {errorMessage && <ErrorMessage text={errorText}></ErrorMessage>}
        </div>
      </div>
    </div>
  );
};
